/* tslint:disable */
import * as React from "react";

interface Props {
  colour: string;
}

const SvgFeedback = (props: Props) => (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='black'
      viewBox='0 0 512 512'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      version='1.1'
      id='Capa_1'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      xmlSpace='preserve'
      {...props}
    >
<g>
    <path d="M323.1,128H120.6c-8.5,0-15.4,6.9-15.4,15.4c0,8.5,6.9,15.4,15.4,15.4h202.5c8.5,0,15.4-6.9,15.4-15.4S331.6,128,323.1,128
		z" />
    <path d="M120.6,283.6h124.8c8.5,0,15.4-6.9,15.4-15.4s-6.9-15.4-15.4-15.4H120.6c-8.5,0-15.4,6.9-15.4,15.4
		S112.1,283.6,120.6,283.6z" />
    <path d="M468.4,65.8c-2.5-23.4-22.3-41.7-46.3-41.7H47.9C22.2,24.1,1.3,45,1.3,70.7v405.4c0,6.3,3.7,11.8,9.5,14.2
		c1.9,0.8,3.9,1.2,5.9,1.2c4.1,0,8-1.6,10.9-4.5l99.4-99.4h295.1c25.7,0,46.6-20.9,46.6-46.6V170.6l28.5-28.5l1.5-1.5v-0.1
		c7.4-8.2,11.7-19.1,11.7-30.1C510.3,86.8,491.8,67.3,468.4,65.8z M479.5,110.5c0,3.7-1.5,7.3-4.1,9.9l-11.7,11.7l-19.8-19.8
		l11.7-11.7c2.6-2.6,6.2-4.1,9.9-4.1C473.2,96.5,479.5,102.7,479.5,110.5z M327.4,248.6l5-24.8l89.8-89.8l19.8,19.8l-89.8,89.8
		L327.4,248.6z M120.6,190.4c-8.5,0-15.4,6.9-15.4,15.4s6.9,15.4,15.4,15.4h180.8l-8.8,43.9c-1,5.1,0.5,10.3,4.2,13.9
		c2.9,2.9,6.8,4.5,10.9,4.5c1,0,2-0.1,3-0.3l52-10.4c3-0.6,5.7-2.1,7.9-4.2l67.3-67.3V341c0,8.7-7.1,15.8-15.8,15.8H120.6
		c-4.1,0-8,1.6-10.9,4.5l-77.7,77.7V70.7c0-8.7,7.1-15.8,15.8-15.8h374.2c8.7,0,15.8,7.1,15.8,15.8v4.5c-1.5,1.2-2.9,2.3-4.1,3.6
		L322.2,190.4H120.6z" />
</g>
</svg>
);

export default SvgFeedback;
