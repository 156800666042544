import React from 'react';

// tslint:disable max-line-length
const ROutroLocalPersonalTranscriptMunch: React.FC = () => (
  <>
    <h5>Postscript</h5>
    <p>Hey. I figured I’d leave you to it.  I wonder... did the gift surprise you, or do you know each other so well that surprises are kind of impossible... Objects in a museum, ...not owned, but given. Maybe one day you’ll return the favour?</p>
  </>
);

export {
  ROutroLocalPersonalTranscriptMunch,
};
