import React from 'react';

// tslint:disable max-line-length
const ROutroRemotePersonalTranscriptMunch: React.FC = () => (
  <>
    <h5>Postscript</h5>
    <p>Hey. I figured I’d leave you to it. I wonder... did the gift surprise you, or do you know each other so well that surprises are kind of impossible? If getting this gift on your phone really gave you a taste for it, you could make a trip to the museum and see how it feels to stand with these objects, like this person was when they were thinking of you.</p>
  </>
);

export {
  ROutroRemotePersonalTranscriptMunch,
};
