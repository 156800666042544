import React from 'react';

/* tslint:disable */
const SvgIconMessenger = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox='0 0 72 72' {...props}>
    <path d='M36.08 0C15.75 0 0 14.86 0 34.92a34.13 34.13 0 0 0 11.33 25.83c1.22 1.09 1 1.72 1.17 8.45a2.9 2.9 0 0 0 3 2.8 2.94 2.94 0 0 0 1.06-.25c7.69-3.37 7.78-3.65 9.09-3.29C47.88 74.58 72 60.34 72 34.92 72 14.86 56.41 0 36.08 0zm21.66 26.87l-10.6 16.78a5.44 5.44 0 0 1-7.49 1.68 4 4 0 0 1-.34-.23l-8.42-6.32a2.18 2.18 0 0 0-2.61 0L16.9 47.41a1.71 1.71 0 0 1-2.48-2.27L25 28.36a5.44 5.44 0 0 1 7.49-1.68 4 4 0 0 1 .34.23l8.43 6.31a2.17 2.17 0 0 0 2.61 0l11.4-8.62a1.7 1.7 0 0 1 2.47 2.27z' />
  </svg>
);
/* tslint:enable */

export default SvgIconMessenger;

