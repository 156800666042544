import React from 'react';

// tslint:disable max-line-length
const ROutroRemoteMuseumTranscriptMunch: React.FC = () => (
  <>
    <h5>Postscript</h5>
    <p>Hey. Well here we are – on the other side. I wonder... did the gift surprise you, or did you know these objects already?... Objects in a museum, ...not owned, but given. Now you've had this gift from the museum, may be you'll visit and make a gift for someone you care about?</p>
  </>
);

export {
  ROutroRemoteMuseumTranscriptMunch,
};
